@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';

.radio-chip {
  position: relative;
  display: block;

  &__label {
    display: inline-flex;
    border-radius: 40px; // NOTE: Custom border radius by design
    background-color: transparent;
    border: 1px solid light.$ge-chips-unselected-default;
    align-items: center;
    padding: spacing.$xs spacing.$m;
  }

  input {
    position: absolute;
    top: 0;
    margin: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
  }

  input:checked ~ &__label {
    background-color: light.$ge-chips-selected-default;
    color: light.$on-ge-on-chips-selected-default;
  }

  input:hover ~ &__label {
    background-color: light.$ge-chips-unselected-hover;
    color: light.$on-ge-on-chips-unselected-default;
  }

  input:checked:hover ~ &__label {
    background-color: light.$ge-chips-selected-hover;
    color: light.$on-ge-on-chips-selected-default;
  }
}
